import React from 'react';

import ActivityBreadCrumb from '../../../components/ActivityBreadCrumbs';
import Layout from '../../../components/layout';
import Seo from '../../../components/Seo';
import RateActivity from '../../../components/RateActivity';

const MissionTwoQuiz = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Mission 2 quiz',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pb-8 pt-8 bg-grey">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pt-5 pb-lg-0">
              <h1 className="h1-two-line-v2 purple mb-12 mb-lg-16 mt-10">
                <span>Quiz!</span>
              </h1>
              <div
                style={{
                  position: 'absolute',
                  top: '150px',
                  left: '250px',
                  height: '120px',
                  width: '120px',
                  transform: 'rotate(180deg)',
                  zIndex: '10',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Arrows/Arrow-3.svg"
                  alt="Illustration of an arrow"
                />
              </div>
              <div className="ml-0 ml-xl-15">
                <h3>
                  We hope you have had fun with Isla, Nero and Kai and have
                  enjoyed the activities. Did you learn lots of fun facts on how
                  to save water?
                </h3>
                <p>
                  Answer the questions in our quiz and ask someone to score you
                  with the answer sheet. Once you have answered them correctly,
                  download your certificate, you’re one step closer to becoming
                  AquaSmart!
                </p>
                <div style={{ position: 'relative' }}>
                  <a
                    href="https://cdn.southeastwater.co.uk/aquasmart/mission-2/SEW-Activities-Set-02-V5_06_Quiz.pdf"
                    target="_blank"
                    className="btn btn-purple mt-3"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      position: 'absolute',
                      top: '-80px',
                      left: '200px',
                      height: '135px',
                      width: '135px',
                      transform: 'rotate(180deg)',
                    }}
                    className="d-none d-lg-block"
                  >
                    <img
                      src="/images/Doodles/Arrows/Arrow-1.svg"
                      alt="Illustration of an arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src="/images/Activities/MissionTwo/SEW-Mission-02-Quiz-01.svg"
                className="w-100"
                alt="Mission one quiz preview"
              />
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-6 col-lg-6">
              <div className="text-center">
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/mission-2/SEW-Activities-Set-02-V5_07_QuizAnswers.pdf"
                  target="_blank"
                  className="btn btn-light-blue mt-2 mr-sm-2 mt-sm-0 lm-2"
                  rel="noreferrer"
                >
                  Answers
                </a>
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/mission-2/Aquasmart-Certificate-02.pdf"
                  target="_blank"
                  className="btn btn-blue mt-2 mt-sm-0"
                  rel="noreferrer"
                >
                  Certificate
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <RateActivity activity="Quiz - Mission 2" />
  </Layout>
);

export default MissionTwoQuiz;
